import styled from '@emotion/styled';
import { BackButton, Chips } from 'components';

export const logoHeaderSize = '110px';

export const Container = styled.div<{ isSticky: boolean; customContent: boolean }>`
    position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: ${({ theme, customContent }) => (customContent ? 0 : theme.base.radius.xl)};
    padding: ${({ theme, customContent }) =>
        customContent ? theme.base.spacing[3] : theme.base.spacing[2]};
    background-color: ${({ theme, customContent }) =>
        customContent ? theme.base.colors.secondary[100] : theme.feature.backgroundColor};
`;

export const AbsoluteBackButton = styled(BackButton)`
    position: absolute !important;
    left: ${({ theme }) => theme.base.spacing[2]};
    top: 50%;
    transform: translateY(-50%);
`;

export const HeaderLogo = styled.div`
    height: ${logoHeaderSize};
    width: ${logoHeaderSize};
    background-size: ${logoHeaderSize};
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(${({ theme }) => theme.base.logoBrandImage});
`;

export const StyledChips = styled(Chips)`
    width: ${({ theme }) => theme.base.spacing[5]};
    position: absolute;
    top: 50%;
    right: ${({ theme }) => theme.base.spacing[2]};
    transform: translateY(-50%);

    > span {
        margin: 0px !important;
    }
`;

export const StyledAccountButtonContainer = styled.div`
    position: absolute;
    top: 50%;
    right: ${({ theme }) => theme.base.spacing[2]};
    transform: translateY(-50%);
`;

export const HeaderTitle = styled.div`
    padding-top: ${({ theme }) => theme.base.spacing[2]};
    padding-bottom: ${({ theme }) => theme.base.spacing[1]};
    padding-left: ${({ theme }) => theme.base.spacing[2]};
`;
