import { useParams } from 'react-router-dom';
import { PriceBreakdown } from 'services/order/order.type';
import { computeRestaurantOrdersForConfirmation } from 'utils/order';
import { useGetOrders } from 'hooks/useGetOrders';
import { useTranslation } from 'react-i18next';
import { useRedirectToHome } from 'hooks/useRedirectHome/useRedirectHome';
import { mapOmnicanalOrderToDiscount } from '../utils';

export const useOrderConfirmationVM = () => {
    const { t } = useTranslation();
    const { orderUuid } = useParams();

    const { redirectToHome } = useRedirectToHome();

    const getOrdersResult = useGetOrders(orderUuid ?? '');
    const { data: order, isLoading, isMultiOrder } = getOrdersResult;

    const priceBreakdown = isMultiOrder
        ? order?.totalPrice
        : ({
              totalExclTax: order?.totalPriceDiscountedWithTaxExcluded,
              totalInclTaxWithDiscount: order?.totalPriceDiscountedWithTaxIncluded,
              totalTax: order?.totalTax,
          } as PriceBreakdown);

    return {
        buttonText: t('get_my_receipt_by_email'),
        isLoading,
        priceBreakdown,
        discounts: isMultiOrder ? [] : mapOmnicanalOrderToDiscount(order),
        entranceFees: isMultiOrder ? [] : order?.entranceFees,
        grants: isMultiOrder ? [] : order?.grants,
        currency: isMultiOrder ? order?.orders[0].currency : order?.currency,
        comment: isMultiOrder ? '' : order?.comment,
        isPaid: isMultiOrder ? false : order?.status === 'PAID',
        restaurantOrders: computeRestaurantOrdersForConfirmation(getOrdersResult),
        redirectToHome,
    };
};
