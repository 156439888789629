import * as React from 'react';
import { AccountButton, Text } from 'components';
import {
    Container,
    AbsoluteBackButton,
    HeaderLogo,
    StyledChips,
    StyledAccountButtonContainer,
} from './WithHeader.style';
import { useWithHeaderVM } from './useWithHeaderVM';

type WithHeaderProps = {
    isSticky?: boolean;
    headerContent?: React.ReactNode;
    disabledClickBack?: boolean;
    disabledClickLogo?: boolean;
    onClickBack?: () => void;
    onClickLogo?: () => void;
    hasAccountButton?: boolean;
};

export const WithHeader: React.FunctionComponent<React.PropsWithChildren<WithHeaderProps>> = ({
    isSticky,
    headerContent,
    disabledClickBack,
    disabledClickLogo,
    onClickBack,
    onClickLogo,
    children,
    hasAccountButton,
}) => {
    const { tableNumber, isFoodCourt, restaurantLogo, logoAriaLabel, goPrevious, redirectToHome } =
        useWithHeaderVM();

    const handleClickBack = () => {
        if (disabledClickBack) return;

        if (onClickBack) {
            onClickBack();
            return;
        }

        goPrevious();
    };

    const handleClickLogo = () => {
        if (disabledClickLogo) return;

        if (onClickLogo) {
            onClickLogo();
            return;
        }

        redirectToHome();
    };

    return (
        <>
            <Container
                isSticky={!!isSticky}
                customContent={!!headerContent}
                data-testid="with-header"
            >
                {!disabledClickBack && (
                    <AbsoluteBackButton onClick={handleClickBack} data-testid="back-button" />
                )}

                {!headerContent && (
                    <HeaderLogo
                        role="img"
                        aria-label={logoAriaLabel}
                        onClick={handleClickLogo}
                        style={
                            isFoodCourt
                                ? {}
                                : {
                                      backgroundImage: `url(${restaurantLogo})`,
                                  }
                        }
                        data-testid={isFoodCourt ? 'brand-logo' : 'restaurant-logo'}
                    />
                )}

                {headerContent && <>{headerContent}</>}

                {tableNumber !== null && (
                    <StyledChips>
                        <Text
                            data-testid="table-number"
                            text={`${tableNumber}`}
                            noTranslation
                            color="inherit"
                            type={3}
                            weight="Regular"
                        />
                    </StyledChips>
                )}
                {hasAccountButton && (
                    <StyledAccountButtonContainer>
                        <AccountButton />
                    </StyledAccountButtonContainer>
                )}
            </Container>
            {children}
        </>
    );
};
