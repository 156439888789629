import React from 'react';
import {
    Button,
    RadioSelect,
    Title,
    PriceBreakDownSummary,
    HeaderTitle,
    WithHeader,
} from 'components';
import {
    computeEntranceFeesFromOrderRecap,
    computeGrantsFromOrderRecap,
    computePromoCodeDiscountFromOrderRecap,
} from 'pages/Cart/Cart.utils';
import { StyledBottomBar, Container, Picker, SummaryContainer } from '../ChoosePayment.style';
import { useChoosePaymentVM } from '../useChoosePaymentVM';

const LuncheonVoucherChoice: React.FunctionComponent = () => {
    const {
        navigateBackToChoosePayment,
        currency,
        totalPriceBreakdown,
        isLoading,
        buttonText,
        paymentMethodLuncheonOptions,
        isLuncheonPaymentMethodSelected,
        handlePaymentModeChange,
        paymentMode,
        handleSubmit,
        recap,
    } = useChoosePaymentVM();

    return (
        <WithHeader isSticky={true} onClickBack={navigateBackToChoosePayment}>
            <Container>
                <HeaderTitle>
                    <Title text={'order_confirmation'} type={4} />
                </HeaderTitle>

                <Picker>
                    <Title text={'payment.luncheon_voucher'} type={5} />
                    <RadioSelect
                        options={paymentMethodLuncheonOptions}
                        selected={paymentMode || paymentMethodLuncheonOptions[0]}
                        onChange={handlePaymentModeChange}
                    />
                </Picker>

                {currency && totalPriceBreakdown && (
                    <SummaryContainer>
                        <PriceBreakDownSummary
                            currency={currency}
                            discounts={computePromoCodeDiscountFromOrderRecap(recap!)}
                            entranceFees={computeEntranceFeesFromOrderRecap(recap)}
                            grants={computeGrantsFromOrderRecap(recap)}
                            {...totalPriceBreakdown}
                        />
                    </SummaryContainer>
                )}

                <StyledBottomBar withPadding>
                    <Button
                        fullWidth
                        type="submit"
                        onClick={handleSubmit}
                        isLoading={isLoading}
                        disabled={isLoading || !isLuncheonPaymentMethodSelected}
                        size="l"
                    >
                        {buttonText.pay}
                    </Button>
                </StyledBottomBar>
            </Container>
        </WithHeader>
    );
};

export default LuncheonVoucherChoice;
